const Navbar = () => {
    return (
        <nav className="navbar">
            <h1>PROPNALYSIS</h1>
            <div className="links">
                {/* <a href="/">Home</a>
                <a href="/cocror">COCROR</a> */}
            </div>
        </nav>

    );
}

export default Navbar;