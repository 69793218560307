import Navbar from './components/Navbar';
import Home from './components/Home';
import Cocror from './components/Cocror';

function App() {
  return (
    <div className="App">
      <Navbar />
      <div className='content'>
        {/* <Home /> */}
        <Cocror />
      </div>
    </div>
  );
}

export default App;
