import { useState } from "react";

const Cocror = () => {
    const [address, setAddress] = useState('');
    const [beds, setBeds] = useState('0');
    const [baths, setBaths] = useState('0');
    const [sqft, setSqft] = useState('');
    const [listingLink, setListingLink] = useState('');
    const [notes, setNotes] = useState('');

    const [price, setPrice] = useState('');
    const [closingCosts, setClosingCosts] = useState('3.0');
    const [otherUpfrontExpenses, setOtherUpfrontExpenses] = useState('0');
    const [monthlyTax, setMonthlyTax] = useState('');
    const [monthlyInsurance, setMonthlyInsurance] = useState('');
    const [monthlyHoa, setMonthlyHoa] = useState('');
    const [vacancy, setVacancy] = useState('5.0');
    const [capEx, setCapEx] = useState('0.50');
    const [repairs, setRepairs] = useState('0.50');
    const [management, setManagement] = useState('3.0');
    const [otherMonthlyExpenses, setOtherMonthlyExpenses] = useState('0');
    
    const [loanApr, setLoanApr] = useState('3.5');
    const [loanTermInMonths, setLoanTermInMonths] = useState('360');
    const [downPayment, setDownPayment] = useState('25.0');

    const [rent, setRent] = useState('');
    const [otherIncome, setOtherIncome] = useState('0');

    // RESULTS
    const [resultsReady, setResultsReady] = useState(false);
    const [resCocror, setResCocror] = useState('');
    const [resTotalCashInvested, setResTotalCashInvested] = useState('');
    const [resTotalMonthlyExpenses, setResTotalMonthlyExpenses] = useState('');
    const [resTotalMonthlyIncome, setResTotalMonthlyIncome] = useState('');
    const [resNetMonthlyIncome, setResNetMonthlyIncome] = useState('');

    const [resClosingCosts, setResClosingCosts] = useState('');
    const [resDownPayment, setResDownPayment] = useState('');
    const [resLoanAmount, setResLoanAmount] = useState('');
    const [resMonthlyPayment, setResMonthlyPayment] = useState('');
    const [resVacancy, setResVacancy] = useState('');
    const [resCapEx, setResCapEx] = useState('');
    const [resRepairs, setResRepairs] = useState('');
    const [resManagement, setResManagement] = useState('');
    

    const handleSubmit = (e) => {
        e.preventDefault();
        runCocror();
        setResultsReady(true);
        window.scrollTo(0, 0);
        
        // const house = { 
        //     address,
        //     beds,
        //     baths,
        //     sqft,
        //     listingLink,
        //     notes,
        //     price,
        //     closingCosts,
        //     otherUpfrontExpenses,
        //     monthlyTax,
        //     monthlyInsurance,
        //     monthlyHoa,
        //     vacancy,
        //     capEx,
        //     repairs,
        //     management,
        //     otherMonthlyExpenses,
        //     loanApr,
        //     loanTermInMonths,
        //     downPayment,
        //     rent,
        //     otherIncome,
        //     resCocror,
        //     resTotalCashInvested,
        //     resTotalMonthlyExpenses,
        //     resTotalMonthlyIncome,
        //     resNetMonthlyIncome,
        //     resClosingCosts,
        //     resDownPayment,
        //     resLoanAmount,
        //     resMonthlyPayment,
        //     resVacancy,
        //     resCapEx,
        //     resRepairs,
        //     resManagement
        // };
        // console.log(house);
        
        // fetch('http://localhost:8000/houses', {
        //     method: 'POST',
        //     headers: { "Content-Type": "application/json" },
        //     body: JSON.stringify(house)
        // }).then(() => {
        //     console.log('COCROR Ran');
        //     setResultsReady(true);
        //     window.scrollTo(0, 0);
        // })
    }

    const runCocror = () => {
        // Down Payment
        let downPaymentResult = price * downPayment / 100;
        setResDownPayment(downPaymentResult);

        // Closing Costs
        let closingCostsResult = price * closingCosts / 100;
        setResClosingCosts(closingCostsResult);

        // Loan Amount
        let loanPrinciple = price * (1 - (downPayment / 100));
        setResLoanAmount(loanPrinciple);

        // Monthly Payment
        /**
         * payment = P[r(1+r)^n/((1+r)^n)-1)]
         * 
         * p = the principal loan amount
         * r = your monthly interest rate
         * n = number of payments over the loan's lifetime
         */
        let p = loanPrinciple;
        let r = loanApr / 12 / 100;
        let n = loanTermInMonths;

        let payment = Math.round((p * (r * (1 + r) ** n) / (((1+r) ** n) - 1)) * 100) / 100;
        setResMonthlyPayment(payment);

        // Vacancy
        let vacancyResult = Math.round((vacancy / 100 * payment) * 100) / 100;
        setResVacancy(vacancyResult);

        // CapEx
        let capexResult = Math.round((price * capEx / 100 / 12) * 100) / 100;
        setResCapEx(capexResult);

        // Repairs
        let repairsResult = Math.round((price * repairs / 100 / 12) * 100) / 100;
        setResRepairs(repairsResult);

        // Management
        let managementResult = Math.round((rent * management / 100) * 100) / 100;
        setResManagement(managementResult);

        // TOTALS
        // Total Monthly Income
        let totalMonthlyIncomeResult = Number(rent) + Number(otherIncome);
        setResTotalMonthlyIncome(totalMonthlyIncomeResult);

        // Total Monthly Expenses
        let totalMonthlyExpenses = Math.round((payment + Number(monthlyTax) + Number(monthlyInsurance) + 
            Number(monthlyHoa) + vacancyResult + capexResult + repairsResult +
            managementResult + Number(otherMonthlyExpenses)) * 100) / 100;
        setResTotalMonthlyExpenses(totalMonthlyExpenses);

        // Total Cash Invested
        let totalCashInvestedResult = Math.round(
            (downPaymentResult + closingCostsResult + Number(otherUpfrontExpenses)) * 100
        ) / 100;
        setResTotalCashInvested(totalCashInvestedResult);

        // Net Monthly Income
        let netMonthlyIncomeResult = Math.round((totalMonthlyIncomeResult - totalMonthlyExpenses) * 100) / 100;
        setResNetMonthlyIncome(netMonthlyIncomeResult);

        // COCROR
        let cocrorResult = Math.round((((netMonthlyIncomeResult * 12) / totalCashInvestedResult) * 100) * 100) / 100;
        setResCocror(cocrorResult);
    }

    const inputForm = () => {
        return (
            <form onSubmit={handleSubmit}>
                <h3>Property Details</h3>
                <label>Address:</label>
                <input
                    type="text"
                    placeholder="123 Wonderful Dr. Martinsburg WV, 25401"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                />
                <label>Beds:</label>
                <select
                    value={beds}
                    onChange={(e) => setBeds(e.target.value)}
                >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                </select>
                <label>Baths:</label>
                <select
                    value={baths}
                    onChange={(e) => setBaths(e.target.value)}
                >
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                </select>
                <label>Sqft:</label>
                <input
                    type="number"
                    min="0"
                    max="99999"
                    value={ sqft }
                    onChange={(e) => setSqft(e.target.value)}
                ></input>
                <label>Listing:</label>
                <input
                    type="url"
                    value={ listingLink }
                    onChange={(e) => setListingLink(e.target.value)}
                ></input>
                <label>Notes:</label>
                <textarea
                    spellCheck="true"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                ></textarea>

                <h3>Expenses (upfront)</h3>
                <label>Price:</label>
                <input
                    type="number"
                    min="0"
                    max="1000000000"
                    required
                    value={ price }
                    onChange={(e) => setPrice(e.target.value)}
                />
                <label>Closing Costs:</label>
                <input
                    type="number"
                    min="0"
                    max="100"
                    step="0.05"
                    required
                    value={ closingCosts }
                    onChange={(e) => setClosingCosts(e.target.value)}
                />
                <label>Other Upfront Expenses:</label>
                <input
                    type="number"
                    min="0"
                    max="1000000000"
                    required
                    value={ otherUpfrontExpenses }
                    onChange={(e) => setOtherUpfrontExpenses(e.target.value)}
                />

                <h3>Expenses (monthly)</h3>
                <label>Tax:</label>
                <input
                    type="number"
                    min="0"
                    max="1000000000"
                    required
                    value={ monthlyTax }
                    onChange={(e) => setMonthlyTax(e.target.value)}
                />
                <label>Insurance:</label>
                <input
                    type="number"
                    min="0"
                    max="1000000000"
                    required
                    value={ monthlyInsurance }
                    onChange={(e) => setMonthlyInsurance(e.target.value)}
                />
                <label>HOA:</label>
                <input
                    type="number"
                    min="0"
                    max="1000000000"
                    required
                    value={ monthlyHoa }
                    onChange={(e) => setMonthlyHoa(e.target.value)}
                />
                <label>Vacancy:</label>
                <input
                    type="number"
                    min="0"
                    max="100"
                    step="0.05"
                    required
                    value={ vacancy }
                    onChange={(e) => setVacancy(e.target.value)}
                />
                <label>CapEx:</label>
                <input
                    type="number"
                    min="0"
                    max="100"
                    step="0.05"
                    required
                    value={ capEx }
                    onChange={(e) => setCapEx(e.target.value)}
                />
                <label>Repairs:</label>
                <input
                    type="number"
                    min="0"
                    max="100"
                    step="0.05"
                    required
                    value={ repairs }
                    onChange={(e) => setRepairs(e.target.value)}
                />
                <label>Management:</label>
                <input
                    type="number"
                    min="0"
                    max="100"
                    step="0.05"
                    required
                    value={ management }
                    onChange={(e) => setManagement(e.target.value)}
                />
                <label>Other Monthly Expenses:</label>
                <input
                    type="number"
                    min="0"
                    max="1000000000"
                    required
                    value={ otherMonthlyExpenses }
                    onChange={(e) => setOtherMonthlyExpenses(e.target.value)}
                />

                <h3>Loan</h3>
                <label>Loan APR:</label>
                <input
                    type="number"
                    min="0"
                    max="100"
                    step="0.05"
                    required
                    value={ loanApr }
                    onChange={(e) => setLoanApr(e.target.value)}
                />
                <label>Loan Term (Months):</label>
                <input
                    type="number"
                    min="0"
                    max="480"
                    step="12"
                    required
                    value={ loanTermInMonths }
                    onChange={(e) => setLoanTermInMonths(e.target.value)}
                />
                <label>Down Payment:</label>
                <input
                    type="number"
                    min="0"
                    max="100"
                    step="0.05"
                    required
                    value={ downPayment }
                    onChange={(e) => setDownPayment(e.target.value)}
                />

                <h3>Income</h3>
                <label>Rent:</label>
                <input
                    type="number"
                    min="0"
                    max="1000000000"
                    required
                    value={ rent }
                    onChange={(e) => setRent(e.target.value)}
                />
                <label>Other Monthly Income:</label>
                <input
                    type="number"
                    min="0"
                    max="1000000000"
                    required
                    value={ otherIncome }
                    onChange={(e) => setOtherIncome(e.target.value)}
                />
                <button>Run</button>
            </form>
        );
    }

    const results = () => {
        return (
            <div className="results">
                <h2>Results</h2>

                <h3>Total</h3>
                <p>COCROR: { resCocror }</p>
                <p>Total Cash Invested: { resTotalCashInvested }</p>
                <p>Total Monthly Expenses: { resTotalMonthlyExpenses }</p>
                <p>Total Monthly Income: { resTotalMonthlyIncome }</p>
                <p>Net Monthly Income: { resNetMonthlyIncome }</p>

                <h3>Loan</h3>
                <p>Loan Amount: { resLoanAmount }</p>
                <p>Monthly Payment: { resMonthlyPayment }</p>
                
                <h3>Expenses (upfront)</h3>
                <p>Down Payment: { resDownPayment }</p>
                <p>Closing Costs: { resClosingCosts }</p>

                <h3>Expenses (monthly)</h3>
                <p>Vacancy: { resVacancy }</p>
                <p>CapEx: { resCapEx }</p>
                <p>Repairs: { resRepairs }</p>
                <p>Management: { resManagement }</p>
            </div>
        );
    }

    return (
        <div className="cocror">
            { resultsReady && results()}
            { inputForm() }
        </div>
    );
    
}

export default Cocror;